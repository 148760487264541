var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-auto"
  }, [_c('Modal', {
    attrs: {
      "modalVisible": _vm.isPreviewModalVisible,
      "closeIconVisible": false,
      "width": "750px",
      "id": "delete"
    },
    on: {
      "close": _vm.tooglePreviewModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [!_vm.isImageReady ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Loading Image ..."
    }
  })], 1) : _vm._e(), _c('div', [_c('img', {
    attrs: {
      "id": "previewImage"
    }
  })])])], 2), _c('div', {
    staticClass: "flex items-center mb-10"
  }, [_c('router-link', {
    staticClass: "mt-1 mr-3 cursor-pointer",
    attrs: {
      "to": "/certificate"
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Edit Certificate Template")])], 1), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "w-4/5"
  }, [_c('div', {
    staticClass: "shadow-lg",
    attrs: {
      "id": "canvas"
    }
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Load Image ..."
    }
  })], 1) : _c('v-stage', {
    ref: "stage",
    attrs: {
      "config": _vm.stageSize
    }
  }, [_c('v-layer', {
    ref: "layer",
    on: {
      "dblclick": _vm.handelDoubleClick,
      "mouseover": _vm.handleMouseOver,
      "mouseout": _vm.handleMouseOut
    }
  }, [_c('v-image', {
    attrs: {
      "config": {
        image: _vm.image,
        width: _vm.stageSize.width,
        height: _vm.stageSize.height
      }
    },
    on: {
      "mousemove": _vm.handleMouseMove
    }
  }), _vm._l(_vm.valueList, function (value, index) {
    return _c('div', {
      key: index
    }, [value.code === 'qrcode' ? _c('div', [_c('v-text', {
      attrs: {
        "config": {
          text: _vm.convertName(value.code),
          fontSize: 12,
          x: _vm.switchCoordinateItems(value).x,
          y: _vm.switchCoordinateItems(value).y - 15
        }
      }
    }), _c('v-image', {
      attrs: {
        "config": {
          x: _vm.switchCoordinateItems(value).x,
          y: _vm.switchCoordinateItems(value).y,
          width: value.image_height / 2.2,
          height: value.image_height / 2.2,
          image: _vm.qrcode
        }
      }
    })], 1) : _c('div', [_c('v-text', {
      attrs: {
        "config": {
          text: _vm.convertName(value.code),
          fontSize: 15,
          x: _vm.switchCoordinateItems(value).x,
          y: _vm.switchCoordinateItems(value).y - 15
        }
      }
    }), _c('v-line', {
      attrs: {
        "config": {
          x: _vm.switchCoordinateItems(value).x,
          y: _vm.switchCoordinateItems(value).y,
          points: [value.field_length, 0, 0, 0, 0, 0],
          tension: 0.5,
          closed: false,
          stroke: 'black'
        }
      }
    })], 1), value.isEdit ? [_c('v-text', {
      attrs: {
        "config": {
          text: "<- Select this position (double click)",
          fill: '#3b82f6',
          fontSize: 12,
          x: value.location_x_moving,
          y: value.location_y_moving
        }
      }
    })] : _vm._e()], 2);
  })], 2)], 1)], 1)]), _c('div', {
    staticClass: "w-1/3 bg-white shadow-lg ml-5 pb-7"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_vm._m(0), _vm._l(_vm.valueList, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "px-5 pb-6"
    }, [_c('div', {
      staticClass: "flex items-center gap-2 border-dashed border-b border-neutral-300 mb-2 pb-1"
    }, [_c('p', {
      staticClass: "text-sm font-bold inline-block",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v(_vm._s(_vm.convertName(value.code)))])]), _c('div', {
      staticClass: "flex items-center gap-3"
    }, [value.code === 'qrcode' ? _c('div', {
      staticClass: "flex items-center gap-3"
    }, [_c('div', {
      staticClass: "w-1/2"
    }, [_c('label', {
      staticClass: "text-11 font-medium text-left block mb-1 leading-4",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v("Image Height")]), _c('TextField', {
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "disabled": !value.isEdit
      },
      on: {
        "keypress": _vm.numbersOnly
      },
      model: {
        value: value.image_height,
        callback: function callback($$v) {
          _vm.$set(value, "image_height", $$v);
        },
        expression: "value.image_height"
      }
    })], 1), _c('div', {
      staticClass: "w-1/2"
    }, [_c('label', {
      staticClass: "text-11 font-medium text-left block mb-1 leading-4",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v("Image Width")]), _c('TextField', {
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "disabled": !value.isEdit
      },
      on: {
        "keypress": _vm.numbersOnly
      },
      model: {
        value: value.image_height,
        callback: function callback($$v) {
          _vm.$set(value, "image_height", $$v);
        },
        expression: "value.image_height"
      }
    })], 1)]) : _c('div', {
      staticClass: "flex items-center gap-3"
    }, [_c('div', {
      staticClass: "w-1/3"
    }, [_c('label', {
      staticClass: "text-11 font-medium text-left block mb-1 leading-4",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v("Font Size")]), _c('TextField', {
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "disabled": !value.isEdit
      },
      on: {
        "keypress": _vm.numbersOnly
      },
      model: {
        value: value.text_size,
        callback: function callback($$v) {
          _vm.$set(value, "text_size", $$v);
        },
        expression: "value.text_size"
      }
    })], 1), _c('div', {
      staticClass: "w-1/3"
    }, [_c('label', {
      staticClass: "text-11 font-medium text-left block mb-1 leading-4",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v("Alignment")]), _c('DropdownAllignment', {
      attrs: {
        "disabled": !value.isEdit,
        "defaultValue": _vm.selectedAlignment[index]
      },
      on: {
        "selectItem": function selectItem($event) {
          return _vm.setAlignment($event, index);
        }
      },
      model: {
        value: _vm.selectedAlignment[index],
        callback: function callback($$v) {
          _vm.$set(_vm.selectedAlignment, index, $$v);
        },
        expression: "selectedAlignment[index]"
      }
    })], 1), _c('div', {
      staticClass: "w-1/3"
    }, [_c('label', {
      staticClass: "text-11 font-medium text-left block mb-1 leading-4",
      class: {
        'text-neutral-300': !value.isEdit
      }
    }, [_vm._v("Length")]), _c('TextField', {
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "disabled": !value.isEdit
      },
      on: {
        "keypress": _vm.numbersOnly
      },
      model: {
        value: value.field_length,
        callback: function callback($$v) {
          _vm.$set(value, "field_length", $$v);
        },
        expression: "value.field_length"
      }
    })], 1)]), _c('div', [_c('div', {
      staticClass: "text-right"
    }, [value.isEdit ? _c('Button', {
      attrs: {
        "disabled": value.isButtonDisable,
        "buttonText": "Set",
        "size": "compact",
        "type": "secondary",
        "additionalClass": "h-8 mt-4"
      },
      on: {
        "action": function action($event) {
          return _vm.editValue(false, index);
        }
      }
    }) : _c('Button', {
      attrs: {
        "buttonText": "Edit",
        "disabled": value.isButtonDisable,
        "size": "compact",
        "type": "secondary",
        "additionalClass": "h-8 mt-4"
      },
      on: {
        "action": function action($event) {
          return _vm.editValue(true, index);
        }
      }
    })], 1)])]), value.isEdit ? _c('div', {
      staticClass: "text-smallest font-medium mt-3 leading-3 bg-yellow-secondary rounded-lg p-2 flex items-start gap-2"
    }, [_c('span', {
      staticClass: "w-6 flex-shrink-0"
    }, [_c('Alert', {
      attrs: {
        "width": "24",
        "height": "24"
      }
    })], 1), _c('div', [_c('p', {
      staticClass: "mb-2"
    }, [_vm._v(" You can set the position of "), _c('strong', [_vm._v(_vm._s(_vm.convertName(value.code)))]), _vm._v(" by "), _c('strong', [_vm._v("double clicking")]), _vm._v(" the point on the image on the left. ")]), _c('p', [_c('strong', [_vm._v("Current Position")]), _vm._v(": " + _vm._s(value.location_x) + ", " + _vm._s(value.location_y) + " (in term of x, y coordinates)")])])]) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_vm.activeIndex === null ? _c('Button', {
    attrs: {
      "buttonText": "Preview",
      "type": "primary",
      "size": "full",
      "additionalClass": "py-3 text-base mx-5 "
    },
    on: {
      "action": function action($event) {
        return _vm.tooglePreviewModal();
      }
    }
  }) : _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "secondary",
      "size": "full",
      "additionalClass": "py-3 text-base mx-5 "
    },
    on: {
      "action": function action($event) {
        return _vm.cancelEditValue();
      }
    }
  })], 1)])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pl-5 pt-7 pb-5"
  }, [_c('div', {
    staticClass: "text-2xl font-semibold"
  }, [_vm._v("Values")])]);
}]

export { render, staticRenderFns }